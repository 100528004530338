import moment from 'moment';
import settings from '../settings';

type DateInput = Date | string | null | undefined | string[];
type FormatOptions = {
  defaultValue?: string;
  format?: string;
};

export const formatDate = (date: DateInput, options?: FormatOptions): string => {
  if (!isDateInputValid(date)) {
    return options?.defaultValue ?? '';
  }

  return moment(date).format(options?.format ?? settings.dateFormat);
};

export const formatToUtcDate = (date: DateInput, options?: FormatOptions): string => {
  if (!isDateInputValid(date)) {
    return options?.defaultValue ?? '';
  }

  return moment(date).utc().format(options?.format ?? settings.dateFormat);
};

export const formatToUtcDateTime = (date: DateInput): string => {
  if (!isDateInputValid(date)) {
    return '';
  }
  return `${formatToUtcDate(date, { format: settings.dateTimeFormat })} UTC`;
};

const isDateInputValid = (date: DateInput): boolean => {
  if (date === null || date === undefined) {
    return false;
  }

  if (typeof date === 'string' || Array.isArray(date)) {
    return moment(date).isValid();
  } else if (date instanceof Date) {
    return !isNaN(date.getTime());
  }

  return true;
};
