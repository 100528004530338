import { createAction } from '@reduxjs/toolkit';
import { ChangelogChangeItem, Contract, Program, } from '../../api/types';
import {
  ItemLoadingErrorPayload,
  LoadStateType,
  PaginatedDataLoadErrorPayload,
  PaginatedDataLoadPayload,
  PaginatedResult,
} from '../commonTypes';
import { State } from '../State';
import { contractChangelogsPageLoadStateSelector, contractLoadStateSelector } from './contractSelectors';
import { add, removeContract, setNotLoadedState, update } from '../contractList/contractListActions';
import { ContractChangelogPaginatedParams } from './contractTypes';
import { ContractStatus } from '../contractList/contractListTypes';
import { clearProgramDataWithRelatedProgramId, deletePrograms, updateStatuses } from '../program/programActions';
import { ProgramStatus, ProgramStatusChanged } from '../program/programTypes';
import { detachAttachedFile, renewAttachedFileState } from '../repository/repositoryActions';
import { AppDispatch } from '../useAppDispatch';
import { AxiosError } from 'axios';
import { apiNames } from '../../services/apiNames';
import apiPaths, { pathById } from '../../services/apiPaths';
import { amplifyApi } from '../../api/AmplifyApi';

export const loading = createAction<string>('contract/loading');
export const load = createAction<Contract>('contract/load');
export const loadingError = createAction<ItemLoadingErrorPayload>('contract/loadingError');
export const creating = createAction('contract/creating');
export const create = createAction<Contract>('contract/create');
export const creatingError = createAction<string>('contract/creatingError');
export const editing = createAction<Contract>('contract/editing');
export const edit = createAction<Contract>('contract/edit');
export const editingError = createAction<any>('contract/editingError');
export const addProgram = createAction<Program>('contract/addProgram');
export const updateProgram = createAction<Program>('contract/updateProgram');
export const removeProgram = createAction<{ contractId: number; programId: number }>(
  'contract/deleteProgram'
);
export const loadingChangelogs = createAction<ContractChangelogPaginatedParams>('contract/loadingChangelogs');
export const loadChangelogs =
  createAction<PaginatedDataLoadPayload<ChangelogChangeItem, ContractChangelogPaginatedParams>>(
    'contract/loadChangelogs'
  );
export const loadingChangelogsError = createAction<
  PaginatedDataLoadErrorPayload<ContractChangelogPaginatedParams>
>('contract/loadingChangelogsError');
export const attachFile = createAction<{ contractId: number; fileName: string }>('contract/attachFile');
export const detachFileFromContract = createAction<number>('contract/detachFile');
export const refreshContractsWithProgramIds = createAction<number[]>('contract/refreshWithProgramIds');

export const loadContract = (id: string) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = contractLoadStateSelector(state, { id });
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(loading(id));
    try {
      const contract = await amplifyApi.get<Contract>(apiNames.AomUI, pathById(apiPaths.contracts, id), {});
      dispatch(load(contract));
    } catch (e: any) {
      const isAxiosError = (error: unknown): error is AxiosError => {
        return (<AxiosError>error).isAxiosError !== undefined;
      }

      if (!isAxiosError(e)) {
        const errorMessage = e?.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
        dispatch(loadingError({ Id: id, errorMessage: errorMessage }));
        return;
      }

      if (e.response?.status == 404) {
        dispatch(loadingError({ Id: id, errorMessage: 'The contract or associated program was not found' }));
      }

    }
  };
};

export const createContract = (contract: Contract) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(creating());
      const newContract = await amplifyApi.post<Contract>(apiNames.AomUI, apiPaths.contracts, { body: contract });
      dispatch(create(newContract));
      dispatch(add(newContract));
      if (newContract.FileName) {
        await dispatch(renewAttachedFileState(newContract.FileName, newContract.Id));
      }
      return newContract;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(creatingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const extendContract = (contract: Contract) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(creating());
      const newContract = await amplifyApi.post<Contract>(apiNames.AomUI, pathById(apiPaths.contracts, contract.Id, 'extend'), { body: contract });
      dispatch(create(newContract));
      dispatch(add(newContract));
      cleanRelatedPrograms(dispatch, contract);
      if (newContract.FileName) {
        await dispatch(renewAttachedFileState(newContract.FileName, newContract.Id));
      }
      return newContract;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(creatingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const deleteContract = (contract: Contract) => {
  return async (dispatch: AppDispatch) => {
    try {
      await amplifyApi.delete(apiNames.AomUI, pathById(apiPaths.contracts, contract.Id), {});

      dispatch(removeContract({ contractId: contract.Id }));
      dispatch(detachAttachedFile(contract.Id));
      if (contract.Programs?.length) {
        const programIds = contract.Programs.map((p) => p.Id);
        dispatch(deletePrograms(programIds));
      }
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(creatingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const editContract = (contract: Contract) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(editing(contract));
      const editedContract = await amplifyApi.put<Contract>(apiNames.AomUI, pathById(apiPaths.contracts, contract.Id), { body: contract });
      editedContract.Programs = contract.Programs;

      dispatch(edit(editedContract));
      dispatch(update(editedContract));
      dispatch(setNotLoadedState());
      dispatch(detachAttachedFile(editedContract.Id));

      if (editedContract.FileName) {
        await dispatch(renewAttachedFileState(editedContract.FileName, editedContract.Id));
      }

      return editedContract;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(editingError({ contract, errorMessage }));
      throw errorMessage;
    }
  };
};

export const cloneContract = (contract: Contract) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(creating());
      const newContract = await amplifyApi.post<Contract>(apiNames.AomUI, pathById(apiPaths.contracts, contract.Id, 'clone'), { body: contract });
      dispatch(create(newContract));
      dispatch(add(newContract));
      cleanRelatedPrograms(dispatch, contract);
      if (newContract.FileName) {
        await dispatch(renewAttachedFileState(newContract.FileName, newContract.Id));
      }
      return newContract;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(creatingError(errorMessage));
      throw errorMessage;
    }
  };
};

export const setContractStatus = (contractId: number, status: ContractStatus) => {
  return async (dispatch: AppDispatch) => {
    const contract = { Id: contractId } as Contract;

    const toProgramStatusChanged = (program: Program): ProgramStatusChanged => {
      return {
        Id: program.Id,
        Status: program.Status as ProgramStatus,
        Version: program.Version ?? null,
        UpdatedBy: program.UpdatedBy ?? null,
      };
    }

    try {
      dispatch(editing(contract));
      const editedContract = await amplifyApi.put<Contract>(
        apiNames.AomUI,
        pathById(apiPaths.contracts, contract.Id, 'status'),
        { queryStringParameters: { status } });
      dispatch(edit(editedContract));
      dispatch(update(editedContract));
      if (editedContract.Programs?.length) {
        const editedPrograms = editedContract.Programs ?? [];
        dispatch(
          updateStatuses(editedPrograms.map(program => toProgramStatusChanged(program)))
        );
      }
      return editedContract;
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(editingError({ contract, errorMessage }));
      throw errorMessage;
    }
  };
};

export const loadContractChangelogs = (searchParams: ContractChangelogPaginatedParams) => {
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const changelogsState = contractChangelogsPageLoadStateSelector(state, searchParams);
    if (changelogsState.type === LoadStateType.Loaded || changelogsState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(loadingChangelogs(searchParams));
    const search = {
      ...searchParams, EntityId: searchParams.id,
      id: undefined,
    };
    try {
      const changelogs = await amplifyApi.get<PaginatedResult<ChangelogChangeItem>>(
        apiNames.AomUI,
        pathById(apiPaths.contracts, searchParams.id, 'changelogs'),
        { queryStringParameters: search }
      );
      dispatch(
        loadChangelogs({
          params: searchParams,
          data: changelogs,
        })
      );
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingChangelogsError({ params: searchParams, errorMessage }));
    }
  };
};


const cleanRelatedPrograms = (dispatch: AppDispatch, contract: Contract) => {
  contract.Programs?.forEach(p => {
    dispatch(clearProgramDataWithRelatedProgramId(p.Id));
  });
}