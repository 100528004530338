const apiPaths: Record<string, string> = {
  contracts: `/api/contracts`,
  carriers: `/api/carriers`,
  operatingCarrierRules: `/api/operatingCarrierRules`,
  airports: `/api/airports`,
  regions: `/api/regions`,
  programs: `/api/programs`,
  files: `/api/files`,
  accounts: `/api/accounts`,
  contractsChangelogs: `/api/contractsChangelogs`,
  programCombinations: `/api/programs/combinationOptions`,
  programListItems: `/api/programs/programListItems`,
  relatedOptions: `/api/programs/relatedOptions`,
};

export const toolsApiPaths: Record<string, string> ={
   contractUpdate: `/api/contractUpdate`
}

export const pathById = (path: string, id: any, subPath?: string) => `${path}/${id.toString()}${subPath ? ('/' + subPath) : ''}`;

export default apiPaths;