import { combineReducers } from 'redux';
import carriersReducer from './carriers/carriersReducer';
import confirmationReducer from './confirmation/confirmationReducer';
import { contractReducer } from './contract/contractReducer';
import contractListReducer from './contractList/contractListReducer';
import currentUserReducer from './currentUser/currentUserReducer';
import marketDataReducer from './marketData/marketDataReducer';
import modalFilesReducer from './modalFiles/modalFilesReducer';
import programReducer from './program/programReducer';
import repositoryReducer from './repository/repositoryReducer';
import { configureStore } from '@reduxjs/toolkit';
import { contractsChangelogsReducer } from './contractsChangelogs/contractChangelogsSlice';
import { State } from './State';
import { relatedProgramsReducer } from './relatedPrograms/relatedProgramsSlice';

export const configureAppStore = (preloadedState: State) => {
  const rootReducer = combineReducers({
    currentUser: currentUserReducer,
    marketData: marketDataReducer,
    contractList: contractListReducer,
    contract: contractReducer,
    carriers: carriersReducer,
    program: programReducer,
    confirmation: confirmationReducer,
    repository: repositoryReducer,
    modalFiles: modalFilesReducer,
    relatedProgramList: relatedProgramsReducer,
    contractsChangelogs: contractsChangelogsReducer
  });

  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
  });
}
