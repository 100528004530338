import { OrderBy } from './components/SortController/SortController';

export const attachContractPopupPageSize = 6;
export const repositoryGridModalPageSize = 10;
export const repositoryPageSize = 15;
export const repositorySortField = 'Date';
export const repositoryOrderBy = OrderBy.Descending;
export const bcdType = 'BCD';
export const airportRecordsBatchLimit = 4000;
export const customerInfoIndex = 1;
export const defaultOperatingCarrier = 'AOM2';

export const usageReportPageSize = 20;
export const defaultUsageReportSortFieldName = "Date Modified";

export const redirectUrlKey = 'REDIRECT_URL';