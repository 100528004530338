import React from 'react';
import { configureAppStore } from '../store/configureAppStore';
import { Provider } from 'react-redux';
import { ConfigurationProvider } from '../services/configurationContext';
import { initialAppState } from './initialAppState';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { PrivateApp } from './PrivateApp';
import { Authentication } from './authentication';
import { Contract } from './contracts/Contract';
import { ContractList } from './contracts/ContractList';
import { Program } from './contracts/Contract/Program';
import { ContractView } from './contracts/Contract/ContractView';

const store = configureAppStore(initialAppState);

const routes = [
  {
    element: <PrivateApp />,
    id: 'root',
    children: [
      {
        path: 'repository',
        id: 'repository',
        children: [
          {
            path: 'files/:fileName',
            id: 'repository_file',
            children: [
              {
                path: 'contracts/:contractId',
                id: 'repository_file_contract',
                element: <Contract />,
                children: [
                  {
                    index: true,
                    async lazy() {
                      const { ContractView } = await import("./contracts/Contract/ContractView/ContractView");
                      return { Component: ContractView };
                    }
                  }
                ]
              },
              {
                path: 'attachContract/*',
                id:'files_attachContract',
                async lazy() {
                  const { RepositoryList } = await import("./repository/RepositoryList/RepositoryList");
                  return { Component: RepositoryList };
                }
              }
            ]
          },
          {
            path: 'list',
            id: 'repository_list',
            async lazy() {
              const { RepositoryList } = await import("./repository/RepositoryList/RepositoryList");
              return { Component: RepositoryList };
            }
          },
          {
            index: true,
            element: <Navigate to={'list'} />,
          }
        ]
      },
      {
        path: 'contracts',
        id: 'contracts',
        children: [
          {
            path: 'create',
            id: 'contracts_create',
            async lazy() {
              const { CreateContract } = await import("./contracts/CreateContract");
              return { Component: CreateContract };
            }
          },
          {
            path: ':contractId',
            id: 'contract',
            async lazy() {
              const { Contract } = await import("./contracts/Contract/Contract");
              return { Component: Contract };
            },
            children: [
              {
                path: 'programs/create',
                id: 'program_create',
                async lazy() {
                  const { CreateProgram } = await import("./contracts/Contract/CreateProgram/CreateProgram");
                  return { Component: CreateProgram };
                }
              },
              {
                id: 'contract_versionComparison',
                path: 'versionComparison',
                async lazy() {
                  const { VersionComparison } = await import("./contracts/Contract/VersionComparison/VersionComparison");
                  return { Component: VersionComparison };
                }
              },
              {
                path: 'changelogs',
                id: 'contract_changelogs',
                async lazy() {
                  const { ContractChangelogs } = await import("./contracts/Contract/ContractChangelogs/ContractChangelogs");
                  return { Component: ContractChangelogs };
                }
              },
              {
                path: 'clone',
                id: 'contract_clone',
                async lazy() {
                  const { CloneContract } = await import("./contracts/Contract/EditContract/EditContract");
                  return { Component: CloneContract };
                }
              },
              {
                path: 'update',
                id: 'contract_update',
                async lazy() {
                  const { UpdateContract } = await import("./contracts/Contract/EditContract/EditContract");
                  return { Component: UpdateContract };
                }
              },
              {
                path: 'extend',
                id: 'contract_extend',
                async lazy() {
                  const { ExtendContract } = await import("./contracts/Contract/EditContract/EditContract");
                  return { Component: ExtendContract };
                }
              },
              {
                path: 'edit',
                id: 'contract_edit',
                async lazy() {
                  const { EditContract } = await import("./contracts/Contract/EditContract/EditContract");
                  return { Component: EditContract };
                }
              },
              {
                path: 'programs/:programId',
                id: 'contract_program',
                element: <Program />,
                children: [
                  {
                    path: 'clone',
                    id: 'program_clone',
                    async lazy() {
                      const { CloneProgram } = await import("./contracts/Contract/Program/EditProgram/EditProgram");
                      return { Component: CloneProgram };
                    }
                  },
                  {
                    path: 'update',
                    id: 'program_update',
                    async lazy() {
                      const { UpdateProgram } = await import("./contracts/Contract/Program/EditProgram/EditProgram");
                      return { Component: UpdateProgram };
                    }
                  },
                  {
                    path: 'edit',
                    id: 'program_edit',
                    async lazy() {
                      const { EditProgram } = await import("./contracts/Contract/Program/EditProgram/EditProgram");
                      return { Component: EditProgram };
                    }
                  },
                  {
                    index: true,
                    id: 'program_view',
                    async lazy() {
                      const { ProgramView } = await import("./contracts/Contract/Program/ProgramView/ProgramView");
                      return { Component: ProgramView };
                    }
                  }
                ]
              },
              {
                index: true,
                element: <ContractView />
              }
            ]
          },
          {
            index: true,
            element: <ContractList />,
          },
        ]
      },
      {
        path: 'usageReport',
        id: 'usage_report',
        async lazy() {
          const { UsageReport } = await import("./usageReport/UsageReport");
          return { Component: UsageReport };
        },
        index: true,
      },
      {
        index: true,
        element: <Navigate to={'/contracts'} />,
      }
    ]
  },
  {
    path: '/auth',
    element: <Authentication />,
    children: [
      {
        path: 'login',
        async lazy() {
          const { Login } = await import("./authentication/Login/Login");
          return { Component: Login };
        }
      },
      {
        path: 'forgotPassword',
        async lazy() {
          const { ForgotPassword } = await import("./authentication/ForgotPassword/ForgotPassword");
          return { Component: ForgotPassword };
        }
      },
      {
        path: 'resetPassword',
        async lazy() {
          const { ResetPassword } = await import("./authentication/ResetPassword/ResetPassword");
          return { Component: ResetPassword };
        }
      },
      {
        index: true,
        element: <Navigate to={'/login'} />,
      }
    ]
  }
];
const browserRouter = createBrowserRouter(routes);

export const App = () => {
  return (
    <Provider store={store}>
      <ConfigurationProvider>
        <RouterProvider router={browserRouter} />
      </ConfigurationProvider>
    </Provider>
  );
}
