import { createReducer } from '@reduxjs/toolkit';
import { LoadStateType } from '../commonTypes';
import * as actions from './contractListActions';
import {create as createProgram } from '../program/programActions';
import { contractListKeySelector } from './contractListSelectors';
import { ContractListPaginatedParams, ContractListState } from './contractListTypes';

const checkStateKey = (state: ContractListState, params: ContractListPaginatedParams) => {
  const key = contractListKeySelector({}, params);
  if (state.key !== key) {
    state.key = key;
    state.pages = [];
    state.activePage = 0;
    state.totalCount = 0;
    delete state.filterOptions;
  }
};

const findContract = (state: ContractListState, contractId: number) => {
  const pageNumbers = Object.keys(state.pages).map((k) => parseInt(k));
  for (let i = 0; i < pageNumbers.length; i++) {
    const contract = state.pages[pageNumbers[i]].data?.find((c) => c.Id === contractId);
    if (contract) {
      return contract;
    }
  }
};

const removeContract = (state: ContractListState, contractId: number) => {
  const pageNumbers = Object.keys(state.pages).map((k) => parseInt(k));
  for (let i = 0; i < pageNumbers.length; i++) {
    const contracts = state.pages[pageNumbers[i]].data;
    const contractIndex = contracts ? contracts?.findIndex((c) => c.Id === contractId) : -1;
    if (contractIndex !== -1 && contracts?.length) {
      contracts.splice(contractIndex, 1);
      return;
    }
  }
};

export const initialState: ContractListState = {
  selectedFilters: {
    Status: [],
  },
  key: '',
  pages: [],
  activePage: 0,
  totalCount: 0
};

const contractListReducer = createReducer<ContractListState>(initialState, (builder) => {
  builder.addCase(actions.loading, (state, action) => {
    checkStateKey(state, action.payload);
    state.pages[action.payload.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loading,
      },
    };
  });
  builder.addCase(actions.load, (state, action) => {
    checkStateKey(state, action.payload.params);
    state.pages[action.payload.params.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Loaded,
      },
      data: action.payload.data.Data,
    };
    state.totalCount = action.payload.data.TotalCount;
    if (action.payload.params.IncludeFilterOptions) {
      state.filterOptions = action.payload.filterOptions;
    }
  });
  builder.addCase(actions.loadingError, (state, action) => {
    checkStateKey(state, action.payload.params);
    state.pages[action.payload.params.PageNumber || 0] = {
      loadState: {
        type: LoadStateType.Error,
        errorMessage: action.payload.errorMessage,
      },
    };
  });
  builder.addCase(createProgram, (state, action) => {
    const contract = findContract(state, action.payload.ContractId);
    if (!contract) {
      return state;
    }
    
    if (contract.Programs) {
      contract.Programs.push(action.payload);
    } else {
      contract.Programs = [action.payload];
    }
    
    return state;
  });
  builder.addCase(actions.setSelectedPage, (state, action) => {
    state.activePage = action.payload.activePage;
  });
  builder.addCase(actions.add, (_state, _action) => {
    return initialState;
  });
  builder.addCase(actions.removeContract, (state, action) => {
    removeContract(state, action.payload.contractId);
  });
  builder.addCase(actions.update, (state, action) => {
    const contract = findContract(state, action.payload.Id);
    if (contract) {
      Object.assign(contract, action.payload);
    }
  });
  builder.addCase(actions.detachFile, (state, action) => {
    const contract = findContract(state, action.payload);
    if (contract) {
      contract.FileName = null;
    }
  });
  builder.addCase(actions.attachContactToFile, (state, action) => {
    const contract = findContract(state, action.payload.contractId);
    if (contract) {
      contract.FileName = action.payload.fileName;
    }
  });
  builder.addCase(actions.setNotLoadedState, (state) => {
    state.pages = [];
  });
  builder.addCase(actions.updateSelectedFilterValues, (state, { payload: selectedOptions }) => {
    state.selectedFilters = { 
      ...selectedOptions,
      EndDate: selectedOptions.EndDate?.map(x => x.toUTCString())
    };
  });
  builder.addCase(actions.resetSelectedFilterValues, (state) => {
    state.selectedFilters = {};
  });
});

export default contractListReducer;
