import { LoadingDataState } from '../commonTypes';
import { ProgramStatus, RelatedProgramsType } from '../program/programTypes';
import { ProgramListItem } from '../../api/types';

export const initialState: RelatedProgramsState = { filters: {}, programs: {} };
export interface RelatedProgramsSearch {
  ValidatingCarrierCodes: Array<string> | null,
  Statuses: Array<ProgramStatus> | null,
  ContractTypes: Array<string> | null,
  RelatedProgramTypes: Array<RelatedProgramsType | null> | null
}

export type RelatedProgramsState = {
  filters: Record<string, LoadingDataState<ProgramListItem[]>>,
  programs: Record<number, ProgramListItem>
}
