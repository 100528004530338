import { createAction } from '@reduxjs/toolkit';
import {
  Carrier,
  OperatingCarriersRule,
} from '../../api/types';
import { LoadStateType } from '../commonTypes';
import { State } from '../State';
import { carriersLoadStateSelector } from './carriersSelectors';
import { AppDispatch } from '../useAppDispatch';
import apiNames from '../../services/apiNames';
import apiPaths from '../../services/apiPaths';
import { amplifyApi } from '../../api/AmplifyApi';

export const loading = createAction('carriers/loading');
export const load = createAction<Carrier[]>('carriers/load');
export const loadingError = createAction<string>('carriers/loadingError');

export const loadCarriers = () => {
  const mergeCarriersWithRules = (carriers: Carrier[], operatingCarrierRules: OperatingCarriersRule[]) => {
    const map: Record<Carrier["Code"], Carrier> = {};
    const res = carriers.reduce((acc, x) => {

      acc[x.Code] = x;
      return acc;
    }, map);

    operatingCarrierRules.forEach((rule) => {
      const carrier = res[rule.OperatingCarrier];
      if (carrier) {
        carrier.Rules = rule;
      }
    });

    return carriers;
  };
  
  return async (dispatch: AppDispatch, getState: () => State) => {
    const state: State = getState();
    const loadState = carriersLoadStateSelector(state);
    if (loadState.type === LoadStateType.Loaded || loadState.type === LoadStateType.Loading) {
      return;
    }

    dispatch(loading());
    try {
      const [carriers, operatingCarrierRules] = await Promise.all([
        amplifyApi.get<Carrier[]>(apiNames.AomUI, apiPaths.carriers, {}),
        amplifyApi.get<OperatingCarriersRule[]>(apiNames.AomUI, apiPaths.operatingCarrierRules, {})]);
      
      mergeCarriersWithRules(carriers, operatingCarrierRules);
      dispatch(load(carriers));
    } catch (e: any) {
      const errorMessage = e.errors ? e.errors.map((er: any) => er.message).join('. ') : e.message;
      dispatch(loadingError(errorMessage));
    }
  };
};