export default function (value?: string | null) {
  if (!value) {
    return undefined;
  }

  if (value.indexOf('.') < 0) {
    return value + '.0';
  }

  return value;
}
