import { createSelector } from '@reduxjs/toolkit';
import { ArrayEmpty, notLoadedState } from '../commonTypes';
import { ProgramStatus, RelatedProgramsType } from '../program/programTypes';
import { stateSelector as rootStateSelector } from '../State';
import { createParameterSelector } from '../useParamsSelector';
import { RelatedProgramsSearch } from './relatedProgramsTypes';

const validatingCarriersParamSelector = createParameterSelector<RelatedProgramsSearch, string[]>(params => params.ValidatingCarrierCodes ?? ArrayEmpty);

const statusesParamSelector = createParameterSelector<RelatedProgramsSearch, ProgramStatus[]>(params => params.Statuses ?? ArrayEmpty);

const contractTypesParamSelector = createParameterSelector<RelatedProgramsSearch, string[]>(params => params.ContractTypes ?? ArrayEmpty);

const relatedProgramTypesParamSelector = createParameterSelector<RelatedProgramsSearch, Array<RelatedProgramsType | null>>(params => params.RelatedProgramTypes ?? ArrayEmpty)


const keySelector = createSelector(
  validatingCarriersParamSelector,
  statusesParamSelector,
  contractTypesParamSelector,
  relatedProgramTypesParamSelector,
  (
    validatingCarriers,
    statuses,
    contractTypes,
    relatedProgramTypes
  ) => {
    type RelatedProgramsSearchPropertyArrayType = RelatedProgramsSearch[keyof RelatedProgramsSearch];
    const addArrayField = (keyElements: string[], arrayField: NonNullable<RelatedProgramsSearchPropertyArrayType>) => {
      if (!arrayField.length) {
        return;
      }

      const arrayFieldCopy = arrayField.slice(0);
      arrayFieldCopy.sort((a, b) => {
        if (a === null) {
          return b === null ? 0 : -1;
        }
        if (b === null) {
          return 1;
        }
        if (typeof a === 'number' && typeof b === 'number') {
          return a - b;
        }
        return a.toString().localeCompare(b.toString());
      });
      keyElements.push(arrayFieldCopy.join(','));
    }
    
    const key: string[] = [];
    addArrayField(key, validatingCarriers);
    addArrayField(key, statuses);
    addArrayField(key, contractTypes);
    addArrayField(key, relatedProgramTypes);

    return key.join('_');
  }
);

const stateSelector = createSelector(
  rootStateSelector,
  keySelector,
  (state, key) => state.relatedProgramList.filters[key]
);

const loadStateSelector = createSelector(
  stateSelector,
  listState => listState?.loadState ?? notLoadedState
);

const listSelector = createSelector(
  stateSelector,
  listState => listState?.data ?? ArrayEmpty
);

export const relatedProgramsSelectors = {
  listSelector,
  loadStateSelector,
  keySelector
};
